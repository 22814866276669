<template>
  <div class="card justify-content-center flex">
    <Dialog v-model:visible="universeStore.visible" modal header="유니버스" :style="{ width: '40rem', height: '40rem' }">
      <!--유니버스 버튼영역 S-->
      <section class="mt-2 flex grow-0 justify-start">
        <div class="flex flex-none gap-1">
          <Button outlined @click="openUnvsMngWindow()"><Icon name="material-symbols:folder-copy-outline" class="mr-1" />유니버스 관리</Button>
        </div>
      </section>
      <!--유니버스 버튼영역 E-->

      <!--유니버스 및 발행사 목록 영역 S-->
      <section class="mt-5 flex grow-0 justify-between">
        <div class="flex-none">
          <DataTable
            v-model:selection="selectedUniverse"
            :value="universes"
            :meta-key-selection="true"
            selection-mode="single"
            data-key="unvsMngC"
            scrollable
            class="border-outline border-collapse border"
            scroll-height="20rem"
            @row-click="onChngUnvs"
            @row-dblclick="onGrpRowDbClick"
          >
            <ColumnGroup type="header">
              <Row>
                <Column header="유니버스" :colspan="2" class="header-title" />
              </Row>
            </ColumnGroup>
            <Column field="unvsMngNm" class="w-35">
              <template #body="{ index, data }">
                <div class="flex">
                  <img :src="data.usid === 'SYSTEM' ? folderSystemIcon : choiceIndex === index ? folderOpenIcon : folderCloseIcon" class="mr-1" alt="Icon" />
                  {{ data.unvsMngNm }}
                </div>
              </template>
            </Column>
            <Column field="iscCnt" class="text-right" />
          </DataTable>
        </div>
        <div class="ml-10 flex-auto">
          <DataTable
            v-model:selection="choiceCo"
            :value="details"
            selection-mode="single"
            :meta-key-selection="true"
            data-key="corpC"
            scrollable
            class="border-outline border-collapse border"
            scroll-height="20rem"
            @row-dblclick="onUnvsRowDbClick"
          >
            <ColumnGroup type="header">
              <Row>
                <Column header="발행사" :colspan="3" class="header-title" />
              </Row>
            </ColumnGroup>
            <Column header="#" class="w-[5%]">
              <template #body="slotProps">
                {{ slotProps.index + 1 }}
              </template>
            </Column>
            <Column field="corpNm" class="w-3/5" />
            <Column field="egCrdRnk" class="w-[35%] text-center" />
          </DataTable>
        </div>
      </section>
      <!--유니버스 및 발행사 버튼영역 E-->

      <!--선택/종료 버튼영역 S-->
      <section class="mt-5 flex grow-0 justify-end">
        <div class="flex flex-none gap-1">
          <Button outlined @click="choiceDetail">선택</Button>
          <Button outlined @click="closePopup">종료</Button>
        </div>
      </section>
      <!--선택/종료 버튼영역 E-->
    </Dialog>
  </div>
</template>

<script setup>
import folderSystemIcon from '@/assets/images/folder-system.svg';
import folderOpenIcon from '@/assets/images/folder-open.svg';
import folderCloseIcon from '@/assets/images/folder-close.svg';

const $api = useApi();
const universeStore = useUniverseStore();
const { openUnvsWindow } = useUniverse();
const universes = ref(universeStore.usrGrpList.value);
const isVisible = computed(() => universeStore.visible);
const selectedUniverse = ref({});
const choiceCo = ref({});
const details = ref([]);
const toast = useSystemToast();
const choiceIndex = ref(0);

// 유니버스 설정창 열기
const openUnvsMngWindow = () => {
  openUnvsWindow([]);
  closePopup();
};

// 유니버스 사용자 그룹 목록 조회
const selectUnvsGrpUsrList = async () => {
  const { usrList } = await $api('/api/U10301/selectUnvsGrpUsrList.do');
  universeStore.usrGrpList.value = usrList;
};

// 법인 보유 유니버스 그룹 조회
const selectUnvsGrpSysList = async () => {
  const { sysList } = await $api('/api/U10301/selectUnvsGrpSysList.do');
  universeStore.sysGrpList.value = sysList[0].iscCnt > 0 ? sysList : [];
};

// 팝업이 열릴때 동작
watch(isVisible, async (flag) => {
  if (flag === true) {
    selectedUniverse.value = {};
    choiceCo.value = {};
    details.value = [];
    await selectUnvsGrpSysList();
    await selectUnvsGrpUsrList();
    universes.value = [...universeStore.sysGrpList.value, ...universeStore.usrGrpList.value];
  }
});

// 유니버스 그룹 선택시 유니버스 그룹의 발행사 목록 조회
const onChngUnvs = async (unvs) => {
  choiceIndex.value = unvs.index;
  if (unvs.data && unvs.data.unvsMngC) {
    if (unvs.data.unvsMngTc === 'USR') {
      const { iscUsrList } = await $api('/api/U10301/selectUnvsIscUsrList.do', { u10301Grp: unvs.data });
      details.value = iscUsrList;
      choiceCo.value = {};
    } else if (unvs.data.unvsMngTc === 'SYS') {
      const { iscSysList } = await $api('/api/U10301/selectUnvsIscSysList.do', { u10301Grp: unvs.data });
      details.value = iscSysList;
      choiceCo.value = {};
    }
  }
};

// 선택 버튼 클릭시 동작
const choiceDetail = () => {
  if (choiceCo.value && choiceCo.value.corpC) {
    callbackUnvsDtl();
  } else if (selectedUniverse.value && selectedUniverse.value.unvsMngC) {
    callbackUnvsGrp();
  } else {
    toast.info('유니버스 발행사가 선택되지 않았습니다.', '발행사 선택');
  }
};

// 유니버스 그룹 더블클릭시 동작
const onGrpRowDbClick = () => {
  callbackUnvsGrp();
};

// 유니버스 상세 발행사 더블클릭시 동작
const onUnvsRowDbClick = () => {
  callbackUnvsDtl();
};

// 유니버스 그룹 콜백함수 실행
const callbackUnvsGrp = () => {
  universeStore.popupProps.callback({ grp: selectedUniverse.value, unvs: details.value });
  closePopup();
};

// 유니버스 발행사 상세 콜백함수 실행
const callbackUnvsDtl = () => {
  universeStore.popupProps.callback({ grp: selectedUniverse.value, unvs: choiceCo.value });
  closePopup();
};

// 종료 버튼 클릭시 동작
const closePopup = () => {
  universeStore.visible = false;
};
</script>

<style lang="css">
.header-title {
  background-color: #eef0f6;
}
.font-color {
  color: #0e4491;
}
.border-outline {
  border-color: #d4d6de;
}
</style>
